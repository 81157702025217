/*custom scrollbar */
html.win ::-webkit-scrollbar {
    position: absolute;
    width: 16px;
}

html.win ::-webkit-scrollbar-track {
    background-color: transparent;
}

html.win ::-webkit-scrollbar-thumb {
    background-color: rgba(187, 187, 187, 1);
    border-radius: 16px;
    border: 4px solid transparent;
    background-clip: content-box;
}

html.win ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(204, 204, 204, 1);
}

/**:not([overflow=hidden]):not([overflow-y=hidden]):not([overflow-x=hidden]) {*/
/*    overflow-y: visible;*/
/*}*/

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.with-scrollbar::-webkit-scrollbar {
    display: block;
}
