/** { font-family: 'Montserrat', serif }*/

.bold { font-weight: bold }
.text-xxs { font-size: 0.5rem }
.font-size-xs   { font-size: 10pt }
.font-size-s    { font-size: 12pt }
.font-size-m    { font-size: 14pt }
.font-size-l    { font-size: 16pt }
.font-size-xl   { font-size: 18pt }
.font-size-xxl  { font-size: 24pt }

@media screen and (max-width: 900px) {
    .font-size-xs   { font-size: 8pt }
    .font-size-s    { font-size: 10pt }
    .font-size-m    { font-size: 11pt }
    .font-size-l    { font-size: 12pt }
    .font-size-xl   { font-size: 14pt }
    .font-size-xxl  { font-size: 16pt }
}

@media screen and (max-width: 600px) {
    .font-size-xs   { font-size: 6pt }
    .font-size-s    { font-size: 8pt }
    .font-size-m    { font-size: 10pt }
    .font-size-l    { font-size: 11pt }
    .font-size-xl   { font-size: 12pt }
    .font-size-xxl  { font-size: 14pt }
}

p {
    line-height: 1 !important;
    margin: .5rem 0;
}

