@tailwind base;
@tailwind components;
@tailwind utilities;

/* Overrides */
.group:active .group-active\:opacity-30 {
    opacity: 0.3 !important;
}

@layer base {
    .bg-info { @apply bg-blue-500 !important }
    .bg-info:hover { @apply bg-blue-700 !important }
    .group:hover .bg-info { @apply bg-blue-700 !important }

    /*.bg-gray { @apply bg-gray-500 !important }*/
    /*.bg-gray:hover { @apply bg-gray-700 !important }*/
    /*.group:hover .bg-gray { @apply bg-gray-700 !important }*/

    .bg-success { @apply bg-green-500 !important }
    .bg-success:hover { @apply bg-green-700 !important }
    .group:hover .bg-success { @apply bg-green-700 !important }

    .bg-warning { @apply bg-yellow-300 !important }
    .bg-warning:hover { @apply bg-yellow-500 !important }
    .group:hover .bg-warning { @apply bg-yellow-500 !important }

    .bg-danger { @apply bg-red-500 !important }
    .bg-danger:not(span):hover { @apply bg-red-700 !important }
    .group:hover .bg-danger:not(span) { @apply bg-red-700 !important }

    .bg-secondary { background: #ff5630 !important }
    .bg-secondary:hover { background: #ff4622 !important }
    .group:hover .bg-secondary { background: #ff4622 !important }
}
