.MuiPickersStaticWrapper-staticWrapperRoot {
    min-width: 200px !important;
}

.MuiTimeline-root .MuiTimelineItem-root:before {
    display: none;
}

.MuiTimelineContent-root.MuiTimelineItem-content {
    padding: 8px 0 8px 12px;
}

.mw-32 { min-width: 32px !important;}
.mw-38 { min-width: 38px !important;}

.flex-basis { flex-basis: 0 }
.cursor-ew-resize { cursor: ew-resize }

.small [class*=MuiOutlinedInput-input] {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
