input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

@media print {
    .print-block {
        page-break-after: auto !important;
        page-break-before: auto !important;
        page-break-inside: avoid !important;
        break-inside: avoid !important;
        position: relative !important;
    }

    .no-print, .no-print * {
        display: none !important;
    }
}

@page {
    size: A4;
    margin: 0;
    /*@bottom-left {*/
    /*    content: counter(page);*/
    /*}*/

    /*@bottom-center {*/
    /*    content: string(title);*/
    /*    text-transform: uppercase;*/
    /*}*/
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}

/*.print-block::before {*/
/*    content: '\a';*/
/*    white-space: pre;*/
/*}*/

.scroll-table-body {
    height: 300px;
    overflow-x: auto;
    margin-top: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.scroll-table table {
    width: 100%;
    table-layout: fixed;
}

.center-vertical {
    top: 50%;
    @apply transform;
    --tw-translate-y: -50%;
    @apply absolute;
}
.center-horizontal {
    left: 50%;
    @apply transform;
    --tw-translate-x: -50%;
    @apply absolute;
}
