@import url('assets/css/tailwind.css');
@import url('assets/css/@tooth.css');
@import url('assets/css/card.css');
@import url('assets/css/font.css');
@import url('assets/css/swal.css');
@import url('assets/css/defaults.css');
@import url('assets/css/backgrounds.css');
@import url('assets/css/material-ui.css');
@import url('assets/css/scroll-bar.css');

.flex-fill { flex: 1 }
.flex { display: flex }
.flex-row { flex-direction: row }
.flex-col { flex-direction: column }
.w-full { width: 100% }
.h-full { height: 100% }
.my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.display-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    height: 6px;
}
.display-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #8993a4;
    /*-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);*/
}

input.hide-controls::-webkit-outer-spin-button,
input.hide-controls::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.hide-controls[type=number] {
    -moz-appearance: textfield;
}


/*@media print {*/
/*    .print-block {*/
/*        page-break-after: auto !important;*/
/*        page-break-before: auto !important;*/
/*        page-break-inside: avoid !important;*/
/*        break-inside: avoid !important;*/
/*        position: relative !important;*/
/*    }*/

/*    .no-print, .no-print * {*/
/*        display: none !important;*/
/*    }*/

/*    body {*/
/*        -webkit-print-color-adjust: exact;*/
/*    }*/
/*}*/

/*@page {*/
/*    size: A4;*/
/*    margin: 0;*/
/*    @bottom-left {*/
/*        content: counter(page);*/
/*    }*/

/*    @bottom-center {*/
/*        content: string(title);*/
/*        text-transform: uppercase;*/
/*    }*/
/*}*/

:disabled, [class*=Mui-disabled] {
    cursor: not-allowed;
}

.css-transition-fade-enter {
    opacity: 0;
}
.css-transition-fade-exit {
    opacity: 1;
}
.css-transition-fade-enter-active {
    opacity: 1;
}
.css-transition-fade-exit-active {
    opacity: 0;
}
.css-transition-fade-enter-active,
.css-transition-fade-exit-active {
    transition: opacity 500ms;
}

/*.with-tooltip-arrow::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: -5px;*/
/*    left: 50%;*/
/*    margin-left: -5px;*/
/*    border-width: 5px;*/
/*    border-style: solid;*/
/*    border-color: transparent transparent black transparent;*/
/*}*/

/*.with-tooltip-arrow.white::before {*/
/*    border-color: transparent transparent white transparent;*/
/*}*/

@keyframes simpleSonarWavesKeyframes {
    0% {
        stroke-opacity: 1;
        stroke-width: 0;
        height: 8px;
        width: 8px;
    }
    50% {
        /*transform: scale(1.1);*/
        height: 12px;
        width: 12px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 16px;
    }
}

[class^=MuiAlert-message] {
    flex: 1;
}
