.card-1, .card-2, .card-3, .card-4, .card-5, .card-neo-1 {
    transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important;
    border-radius: 2px !important;
}

.card-1 {
    cursor: pointer !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;
}

.card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
    /*border-radius: 8px !important;*/
    z-index: 800 !important;
}

.card-1:active {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}

.card-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}

.card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}

.card-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
}

.card-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) !important;
}

.card-neo-1 {
    cursor: pointer !important;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important;
}

.card-neo-1:hover {
    transform: scale(0.9) !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.48) !important;
}
