.simple-jaw .tooth {
    stroke: transparent;
    fill: transparent;
    cursor: pointer;
}

.simple-jaw .writings text {
    pointer-events: none;
    fill: black;
}

.simple-jaw .writings2 text, .writings10 text {
    font-size: 43px;
}

.simple-jaw .writings3 text, .writings4 text, .writings5 text, .writings11 text {
    font-size: 54px;
}

/* Tooth square */
.simple-jaw path.toothSquare {
    display: none;
}

.simple-jaw path.toothSquare.selectedInFormula {
    display: block;
    fill: white;
}

.simple-jaw path.usedInFormula {
    fill: rgba(255, 255, 255, .5);
    stroke: #73b5e1;
    stroke-width: 4px;
    display: block;
}

.simple-jaw path.tooth.toothSquare {
    stroke: none !important;
    fill: white;
}

.simple-jaw path.tooth.toothSquare.selectedInFormula {
    fill: white !important;
}

/* Tooth jaw name */
.simple-jaw text.toothJawName.selectedInFormula, text.toothJawName.usedInFormula,
.simple-jaw text.toothSegmentName.selectedInFormula, text.toothSegmentName.usedInFormula,
.simple-jaw text.toothQuadrantName.selectedInFormula, text.toothQuadrantName.usedInFormula {
    display: none;
}

.simple-jaw path.toothShape.selectedInFormula, .planStandardView path.toothShape.selectedToChangeStatusInFormula {
    stroke: #73b5e1;
    fill: white;
    stroke-width: 7;
}

.simple-jaw path.toothShape.selectedInFormula {
    fill: white !important;
}

